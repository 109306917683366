// Here you can add other styles
$primary-custom-color: #4a63ae;
$primary-custom-text-color: white;
$secondary-custom-text-color: white;
$menu-custom-text-primary-color:black;
$secondary-custom-color: #ED1B23;

.nav-item{
  //background-color: white!important;
}

.nav-link-danger.nav-link.active{
  background-color: #ffffff!important;
  color:black!important;
}


.nav-title{
  color: black!important;
}

.nav-link.active{
  background-color: $primary-custom-color!important;
  color:$primary-custom-text-color!important;
}

.nav-link{
  color:$menu-custom-text-primary-color!important;
}


.sidebar .nav-link.active .nav-icon {
  color: $secondary-custom-color;
}


.nav-link.nav-link-danger:hover, .nav-link:hover  {
  background-color: $primary-custom-color!important;
  color: $primary-custom-text-color!important;
}

.app-header.navbar{
  background-color: white!important;
}

.sidebar-minimizer.mt-auto{
  background-color: $primary-custom-color!important;
}


.navbar-toggler-icon {
  height: 23px;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(74,99,174)' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")!important;
}

.buttonPrimaryCustomColor{
  background-color: $primary-custom-color;
  color:$primary-custom-text-color;
}

.buttonPrimaryCustomColor:hover{
  background-color: $secondary-custom-color;
  color:$secondary-custom-text-color;
}

.borderPrimaryCustomColor{
  border-color: $primary-custom-color;
}

 .switch-primary .switch-input:checked + .switch-slider {
   background-color: $primary-custom-color!important;
   border-color: #c8ced3!important;
 }

.switch-primary .switch-input:checked + .switch-slider::before {
  border-color: #c8ced3;
}

.bold{
  font-style: bold;
}
.font-weight-bold{
  font-weight: 700!important;
}

.italic{
  font-style: italic;
}

.alignLeft{
  text-align: left;
}
.alignRight{
  text-align: right;
}

.alignCenter{
  text-align: center;
}

.verticalAlignMiddle{
  margin-top : auto;
  margin-bottom : auto;
}
.marginBottom10{
  margin-bottom : 10px;
}

.marginLRauto{
  margin-left : auto;
  margin-right : auto;
}

.marginX10{
  margin-left : 10px;
  margin-right : 10px;
}

.marginL10{
  margin-left : 10px;
}

.media-list {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-top: 1.25rem;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

.mt-3, .my-3 {
  margin-top: 1.25rem!important;
}

.mr-3, .mx-3 {
  margin-right: 1.25rem!important;
}

.position-relative {
  position: relative!important;
}

.no-padding{
  padding:0px
}


.tags-input {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 98%;
  padding: 0 8px;
  //border: 1px solid rgb(214, 216, 218);
  //border-radius: 6px;
  &:focus-within {
   // border: 1px solid #0052cc;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 8px 0 0 0;
    li {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0 8px;
      font-size: 14px;
      list-style: none;
      border-radius: 6px;
      margin: 0 8px 8px 0;
      background: #0052cc;
      span {
        margin-top: 3px;
      }
      i {
        font-size: 14px;
        margin-left: 15px;
        color: #fff;
        border-radius: 10%;
        //background: #fff;
        cursor: pointer;
      }
    }
  }
  input {
    flex:1;
    //border: none;
    height: 46px;
    width: 95%;
    font-size: 14px;
    //padding: 4px 0 0 0;
    padding-bottom: 15px;
    padding-top: 15px;
    &:focus {
      outline: transparent;
    }
  }
}

@media screen and (max-width: 567px) {
  .tags-input {
    width: calc(100vw - 32px);
  }
}

.card-header{
  margin-bottom: 0px;
}

.card-body-noPadding{
  padding-top: 5px;
  padding-bottom: 5px;
}

.form-group-noPadding{
  margin-bottom: 5px;
  margin-top:5px
}

.flex1{
  flex: 1
}

select {
  border-color: #e4e7ea;
  min-height: 35px;
}

//utile per visualizzare react-select in overlay rispetto al box sottostante
.css-26l3qy-menu {
  z-index: 1000!important;
}

// modale di errore ModalError
.modal-header-error {
  background-color: $primary-custom-color;
  color:white;
  justify-content: center;
}

.modal-header-error > .close{
  opacity:unset;
  color:white;
}

.modal-header-error-icon{
  text-align:center
}

.modal-body-error{
  text-align: center;
}

.modal-header-error > .close{
  opacity:unset;
  color:white;
}


.modal-body-error{
  text-align: center;
}

.img-error{
  width:40%
}


.modalWidth900px {
  max-width: 900px;
}

.modalWidth1024px {
  max-width: 90%;
}

.modalWidth1024px > .modal-content{
  height: 80%;
}
